import { Grid, IconButton, Tooltip } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  actions as appActions,
  selectors as appSelectors,
} from 'redux/app/app.slice';
import AddressAutocomplete from '../address-autocomplete/address-autocomplete.component';

const name = 'pickupSearch';
const PickupLocationsSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setValue } = useFormContext();
  const [searchByGeoLocation, setSearchByGeoLocation] = useState(false);
  const searchLocation = useSelector(appSelectors.selectSearchLocation);

  const handleChange = value => {
    setSearchByGeoLocation(false);
    dispatch(appActions.setSearchLocation(value));
  };

  const successCallback = position => {
    const location = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    setSearchByGeoLocation(true);
    dispatch(appActions.setGeoLocation(location));
    dispatch(appActions.setSearchLocation(undefined));
  };

  const errorCallback = error => {
    if (error.code === 1)
      toast.error(
        t('locations.pickupInput.locationSearch.geoLocationErrors.permission'),
      );
    else
      toast.error(
        t('locations.pickupInput.locationSearch.geoLocationErrors.failure'),
      );
    dispatch(appActions.setGeoLocation(undefined));
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  return (
    <Grid container>
      <Grid item xs={11}>
        <AddressAutocomplete
          name={name}
          defaultValue={searchLocation}
          autocompleteInputProps={{
            placeholder: t('locations.pickupInput.locationSearch.placeholder'),
          }}
          autocompleteProps={{
            forcePopupIcon: false,
            handleChange,
            ListboxProps: {
              'data-testid': 'AddressSelect.CustomSelect.Menu',
            },
            'data-testid': 'AddressSelect.CustomSelect',
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Tooltip title={t('locations.pickupInput.label')}>
          <IconButton
            disabled={searchByGeoLocation}
            onClick={() => {
              getCurrentLocation();
              setValue(name, null);
            }}
            data-testid="PickupLocationSearch.CurrentLocation"
          >
            <MyLocationIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default PickupLocationsSearch;
