import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import OrderTypeTabs from '../order-type-tabs/order-type-tabs.component';

const OrderTypeSelector = ({ types, defaultValue, name }) => {
  const { control } = useFormContext();

  if (types?.length > 0) {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, ref } }) => (
          <OrderTypeTabs
            value={value}
            onChange={onChange}
            ref={ref}
            types={types}
          />
        )}
      />
    );
  } else {
    return null;
  }
};

export default memo(OrderTypeSelector);
