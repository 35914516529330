import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectAreAllLocationsTypesLoadedOnce } from 'redux/locations/locations.selectors';

export const useSetDefaultOrderType = defaultTab => {
  const { setValue } = useFormContext();
  const { fieldNames } = useFieldNames();
  const isAllLocationsLoaded = useSelector(
    selectAreAllLocationsTypesLoadedOnce,
  );

  useEffect(() => {
    setValue(fieldNames.orderType, defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllLocationsLoaded]);
};
