import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import { selectRequestedAddressIsValid } from 'redux/cart/cart.selectors';
import {
  selectIsCatering,
  makeSelectIsMenuBrowsingStore,
} from 'redux/locations/locations.selectors';
import { CARD_STEP } from '../../../locations.constants';
import * as S from './location-card-overview-buttons.styles';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { OrderTypeContext } from 'pages/store/new-order-form/order-form-context';
import { useOrderTypeSelectors } from 'pages/store/new-order-form/useOrderTypeSelectors.hooks';
import { useViewMenuClicked } from 'hooks/useViewMenuClick';
import { useStoreDisplayInfo } from 'hooks/useStoreDisplayInfo';
import { useIsViewOnlyLocationEnabled } from 'hooks/useIsViewOnlyLocationEnabled';

const LocationCardOverviewButtons = ({
  store,
  handleStep,
  disabled,
  setSelectedStore,
}) => {
  const { t } = useTranslation();
  const isCateringStore = useSelector(selectIsCatering(store.storeId));
  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(store.storeId),
  );
  const viewOnlyLocationEnabled = useIsViewOnlyLocationEnabled(store);

  const { setValue, register, trigger } = useFormContext();
  const {
    fieldNames: { cateringOrderType },
  } = useFieldNames();
  register(cateringOrderType);

  const selectedOrderType = useContext(OrderTypeContext);
  const isValidRequestedAddress = useSelector(selectRequestedAddressIsValid);

  const { storeDITitle } = useStoreDisplayInfo(store);

  const { orderTypes } = useOrderTypeSelectors(store, isCateringStore);

  const handleMenuBrowsingClick = useViewMenuClicked(
    store.displayInfo?.[0]?.title,
    store.storeId,
  );

  const handleOrderClick = orderType => {
    setSelectedStore(store);

    if (isCateringStore) {
      setValue(cateringOrderType, orderType);
    }

    if (!isValidRequestedAddress && orderType === 'DELIVERY') {
      scroller.scrollTo('LocationsAddressControls.Container', {
        smooth: true,
        containerId: 'locationsListContainer',
      });
      trigger('address');

      toast.error(t('errors.requiredAddress'));
      return;
    }

    setTimeout(() => {
      scroller.scrollTo(store.storeId, {
        smooth: true,
        containerId: 'locationsListContainer',
        offset: -5,
      });

      handleStep(CARD_STEP.ORDER_OPTIONS, orderType);
    }, 10);
  };

  if (viewOnlyLocationEnabled || disabled) {
    return null;
  }

  if (store.externalOrderingEnabled) {
    return <S.ExternalOrderButton store={store} />;
  }

  if (isMenuBrowsingStore) {
    return (
      <S.OrderButton
        aria-label={t('viewOnlyMenu.button.ariaLabel', {
          store: storeDITitle,
        })}
        onClick={handleMenuBrowsingClick}
        data-testid="LocationCardOverviewButtons.CustomButton.MenuBrowsing"
      >
        {t('viewOnlyMenu.button.label')}
      </S.OrderButton>
    );
  }

  if (isCateringStore) {
    return orderTypes.map(({ type, label, ...rest }) => (
      <S.OrderButton
        key={label}
        onClick={() => handleOrderClick(type)}
        {...rest}
      >
        {label}
      </S.OrderButton>
    ));
  }

  return (
    <S.OrderButton
      aria-label={t('locations.card.orderAction.ariaLabel', {
        store: storeDITitle,
      })}
      onClick={() => handleOrderClick(selectedOrderType)}
      data-testid="LocationCardOverviewButtons.CustomButton.Order"
    >
      {t('locations.card.orderAction.label')}
    </S.OrderButton>
  );
};

export default LocationCardOverviewButtons;
