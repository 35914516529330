import IncButton from 'components/inc-button/inc-button.component';
import { useStoreDisplayInfo } from 'hooks/useStoreDisplayInfo';
import { useViewMenuClicked } from 'hooks/useViewMenuClick';
import NewOrderForm from 'pages/store/new-order-form/new-order-form.component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';
import * as S from './store-actions.styles';

const StoreActions = ({ store }) => {
  const { storeTitle } = useParams();
  const { t } = useTranslation();
  const handleMenuBrowsingClick = useViewMenuClicked(storeTitle, store.storeId);
  const isMenuBrowsingStore = useSelector(
    makeSelectIsMenuBrowsingStore(store.storeId),
  );
  const { storeDITitle } = useStoreDisplayInfo(store);

  if (store.externalOrderingEnabled)
    return <S.ExternalOrderButton store={store} fullWidth />;

  if (isMenuBrowsingStore)
    return (
      <IncButton
        fullWidth
        onClick={handleMenuBrowsingClick}
        data-testid="StoreActions.IncButton"
        aria-label={t('viewOnlyMenu.button.ariaLabel', {
          store: storeDITitle,
        })}
      >
        {t('viewOnlyMenu.button.label')}
      </IncButton>
    );

  return <NewOrderForm store={store} />;
};

export default StoreActions;
