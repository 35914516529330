import ExternalOrderingButton from 'components/external-ordering-button/external-ordering-button.component';
import IncButton from 'components/inc-button/inc-button.component';
import styled from 'styled-components';

const orderButtonStyles = `
   margin: 0 0.5em;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 100px;
  }`;

export const OrderButton = styled(IncButton)`
  ${orderButtonStyles}
`;

export const ExternalOrderButton = styled(ExternalOrderingButton)`
  ${orderButtonStyles}
`;
