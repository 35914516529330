import { Box } from '@material-ui/core';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useWatch } from 'react-hook-form';
import {
  getOrderTypePanelId,
  getOrderTypeTabId,
} from '../order-type-tabs.utils';

const OrderTypeTabsPanel = ({ value, orderTabs, children }) => {
  const { fieldNames } = useFieldNames();
  const orderType = useWatch({ name: fieldNames.orderType });

  if (!orderTabs.some(({ type }) => type === value)) {
    return null;
  }

  return (
    <Box
      id={getOrderTypePanelId(value)}
      role="tabpanel"
      aria-labelledby={getOrderTypeTabId(value)}
      sx={{
        flex: orderType === value ? 1 : 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {orderType === value && children}
    </Box>
  );
};
export default OrderTypeTabsPanel;
