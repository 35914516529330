import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const finderContainerWidth = css`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 33.3333%;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 500px;
    width: 50%;
  }
`;

export const ListContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  flex: 1;
`;

export const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  order: 1;

  position: relative;

  ${finderContainerWidth}
`;

export const FinderTitle = styled(Typography)`
  margin-bottom: 2em;
`;

export const StickyContainer = styled(Box)`
  position: fixed;
  top: ${({ theme }) => theme.navHeight};
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(2)}px;

  ${finderContainerWidth}

  .input-container {
    margin-bottom: 0;
  }
`;

export const ForcedOrderTypeTitle = styled(Typography)`
  font-weight: 500;
`;
