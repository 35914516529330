import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  selectIsCatering,
  selectRequestedStore,
} from 'redux/locations/locations.selectors';
import { useSelector } from 'react-redux';
import { selectOrderOption, selectOrderType } from 'redux/cart/cart.selectors';
import { selectRequestedFulfillTimeAvailabilityObject } from 'redux/data/orderavailability/orderavailability.selectors';
import { Box, Divider } from '@material-ui/core';
import AddressControls from 'components/address/address-controls/address-controls.component';
import Form from 'components/form/form.component';
import OrderOptions from 'components/new-order/order-options/order-options.component';
import OrderTypeSelector from 'components/new-order/order-type-selector/order-type-selector.component';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useOrderTypeSelectors } from 'pages/store/new-order-form/useOrderTypeSelectors.hooks';
import useUnregisterAddressForPickup from 'pages/store/new-order-form/useUnregisterAddressForPickup.hooks';
import useResetOrderOption from 'pages/store/new-order-form/useResetOrderOption';
import CheckoutOrderAvailability from 'pages/checkout/checkout-order-details-section/checkout-order-details-edit/checkout-order-availability/checkout-order-availability.component';
import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import RHFDevtoolsWrapper from 'components/rhf-devtools-wrapper/rhf-devtools-wrapper.component';
import ScheduleOrder from 'components/new-order/schedule-order/schedule-order.component';
import { useGetAddressLabel } from 'components/address/address-controls/address-controls.hooks';

const RescheduleOrder = ({ handleTimeChange }) => {
  const { t } = useTranslation();
  const { unregister, setValue, control } = useFormContext();
  const store = useSelector(selectRequestedStore);
  const isCatering = useSelector(selectIsCatering(store.storeId));
  const originalOrderType = useSelector(selectOrderType);
  const { orderTypes } = useOrderTypeSelectors(store);
  const { fieldNames } = useFieldNames();
  const orderType = useWatch({
    name: fieldNames.orderType,
    defaultValue: originalOrderType,
  });
  const address = useWatch({ name: fieldNames.address });
  const day = useWatch({ name: fieldNames.day });
  const label = useGetAddressLabel(isCatering);

  const { pickupLabel, deliveryLabel } = useOrderTypeLabels();
  useUnregisterAddressForPickup(orderType, unregister);
  useResetOrderOption(store, orderType, setValue);

  const rftao = useSelector(state =>
    selectRequestedFulfillTimeAvailabilityObject(state, store.storeId),
  );

  const defaultDay = useMemo(
    () => rftao?.displayDateTime?.toISODate(),
    [rftao],
  );

  const defaultOrderOption = useSelector(selectOrderOption);
  return (
    <>
      <CheckoutOrderAvailability
        store={store}
        orderType={orderType}
        address={address}
        selectedDay={!!day?.isLuxonDateTime ? day.toISODate() : undefined}
      />
      <Form>
        <Box mb={1}>
          <OrderTypeSelector
            types={orderTypes}
            defaultValue={orderType}
            name={fieldNames.orderType}
          />
        </Box>

        <Box mb={1}>
          <Divider orientation="horizontal" />
        </Box>

        {orderType === 'DELIVERY' && (
          <AddressControls orderType={orderType} label={label} />
        )}

        <Box position="relative" minHeight="200px">
          {address !== 'new' && (
            <>
              <OrderOptions
                store={store}
                orderType={orderType}
                label={
                  orderType === 'PICKUP'
                    ? t('orderOptionLabel.pickup', { pickupLabel })
                    : t('orderOptionLabel.delivery', { deliveryLabel })
                }
                defaultValue={defaultOrderOption}
              />
              <ScheduleOrder
                store={store}
                orderType={orderType}
                defaultDay={defaultDay}
                defaultTime={rftao}
                onTimeClick={handleTimeChange}
              />
            </>
          )}
        </Box>
        <RHFDevtoolsWrapper control={control} />
      </Form>
    </>
  );
};

export default RescheduleOrder;
