import IncButton from 'components/inc-button/inc-button.component';
import { useStoreDisplayInfo } from 'hooks/useStoreDisplayInfo';
import { useTranslation } from 'react-i18next';

const ExternalOrderingButton = ({ store, ...rest }) => {
  const { t } = useTranslation();
  const { storeDITitle } = useStoreDisplayInfo(store);

  return (
    <IncButton
      href={store.externalOrderingText}
      target="_blank"
      aria-label={t('externalOrdering.ariaLabel', {
        store: storeDITitle,
      })}
      data-testid="ExternalOrderingButton.IncButton"
      {...rest}
    >
      {t('locations.card.orderAction.label')}
    </IncButton>
  );
};

export default ExternalOrderingButton;
