import { Box, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ActionLink from 'components/action-link/action-link.component';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import PromoCode from 'components/promo-code/promo-code.component';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderOffers } from 'redux/cart/cart.actions';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import TotalItem from '../../../components/total-item/total-item.component';
import {
  selectCharges,
  selectDisplayOrderSubTotal,
  selectOrderAndItemDiscounts,
  selectTaxes,
  selectDisplayTotalWithoutGratuityWithTip,
} from '../../../redux/cart/cart.selectors';
import CheckoutSection from '../checkout-section/checkout-section.component';
import AppliedOffer from './applied-offer/applied-offer.component';
import CheckoutTip from './checkout-tip/checkout-tip.component';
import { useCheckoutTip } from './checkout-tip/checkout-tip.hooks';

const CheckoutSummarySection = ({ ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subtotal = useSelector(selectDisplayOrderSubTotal);
  const displayTotalWithTip = useSelector(
    selectDisplayTotalWithoutGratuityWithTip,
  );
  const charges = useSelector(selectCharges);
  const taxes = useSelector(selectTaxes);
  const { show: showTip, tip } = useCheckoutTip();
  const discounts = useSelector(selectOrderAndItemDiscounts);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { open, handleClose, handleOpen } = useCustomDialog();

  const onRetrieve = useCallback(
    result => {
      !!result && isLoggedIn && dispatch(getOrderOffers());
    },
    [dispatch, isLoggedIn],
  );

  return (
    <CheckoutSection
      title={t('checkout.orderSummary.title')}
      data-testid="CheckoutSummarySection.CheckoutSection"
      {...rest}
    >
      <Box mb={1}>
        {subtotal && (
          <TotalItem data-testid="CheckoutSummarySection.Subtotal">
            <Typography>{t('checkout.orderSummary.subtotal')}</Typography>
            <Typography>${subtotal}</Typography>
          </TotalItem>
        )}

        <Box>
          {charges &&
            charges.map(
              ({ chargeDescription, displayChargeApplied }, index) => (
                <TotalItem
                  key={index}
                  data-testid="CheckoutSummarySection.Charge"
                >
                  <Typography>{chargeDescription}</Typography>
                  <Typography>${displayChargeApplied}</Typography>
                </TotalItem>
              ),
            )}

          {taxes &&
            taxes.map(({ taxDescription, displayTaxAmountApplied }, index) => (
              <TotalItem key={index} data-testid="CheckoutSummarySection.Tax">
                <Typography>{taxDescription}</Typography>
                <Typography>${displayTaxAmountApplied}</Typography>
              </TotalItem>
            ))}

          {displayTotalWithTip > 0 && (
            <TotalItem>
              <Typography>{t('checkout.orderSummary.discounts')}</Typography>
              <Typography>
                <ActionLink
                  onClick={handleOpen}
                  data-testid="CheckoutSummarySection.ActionLink.PromoCode"
                >
                  {t('checkout.orderSummary.promoCode')}
                </ActionLink>
              </Typography>
            </TotalItem>
          )}

          {discounts?.map(discount => (
            <AppliedOffer discount={discount} key={discount.discountId} />
          ))}

          {showTip && (
            <>
              <Box my={1}>
                <Divider />
              </Box>
              <Box mb={1}>
                <TotalItem data-testid="CheckoutSummarySection.Tip">
                  <Typography id="tip-label">
                    {t('checkout.orderSummary.tip')}
                  </Typography>
                  <Typography>${tip.toFixed(2)}</Typography>
                </TotalItem>
              </Box>
              <CheckoutTip />
            </>
          )}
        </Box>
      </Box>

      <Divider />

      <Box
        mt={1}
        display="flex"
        justifyContent="space-between"
        data-testid="CheckoutSummarySection.Total"
        aria-live="polite"
        aria-atomic="true"
      >
        <Typography component="h3" variant="h6">
          <span>{t('checkout.orderSummary.total')}</span>
        </Typography>
        <Typography component="h3" variant="h6">
          <span>${displayTotalWithTip}</span>
        </Typography>
      </Box>

      <PromoCode
        open={open}
        handleClose={handleClose}
        checkoutPromoCode
        onRetrieve={onRetrieve}
      />
    </CheckoutSection>
  );
};

export default CheckoutSummarySection;
