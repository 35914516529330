import { Box } from '@material-ui/core';
import StorePhone from 'components/store/store-phone/store-phone.component';
import { useIsStoreNotWithinDeliveryDistance } from 'hooks/useIsStoreWithinDeliveryDistance';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'redux/app/app.slice';
import { CATERING } from 'util/constants';
import LocationCardOverviewButtons from '../location-card-overview-buttons/location-card-overview-buttons.component';
import * as S from './overview-sub-content.styles';
import OrderingHours from '../ordering-hours/ordering-hours.component';

const OverviewSubContent = props => {
  const { store } = props;
  const locationType = useSelector(selectors.selectLocationsLocationType);
  const { t } = useTranslation();
  const isStoreNotWithinDeliveryDistance =
    useIsStoreNotWithinDeliveryDistance(store);

  const showCateringDeliveryDistanceError =
    isStoreNotWithinDeliveryDistance && locationType === CATERING;

  return (
    <>
      {showCateringDeliveryDistanceError && (
        <Box display="flex" marginTop="10px">
          <S.ErrorIcon color="error" />
          <S.DeliveryRadiusError variant="body2" color="error">
            {t('deliveryDistance.catering.text')}
          </S.DeliveryRadiusError>
        </Box>
      )}
      <Box mt={1}>
        <OrderingHours {...props} />
        <Box display="flex" mt={2} alignItems="center">
          <Box flexDirection="column" justifyContent="flex-start">
            <StorePhone phoneNumber={store.phoneNumber} />
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end">
            <LocationCardOverviewButtons {...props} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OverviewSubContent;
