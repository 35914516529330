import { createSelector } from 'reselect';
import {
  selectLocationId,
  selectOrderType,
  selectRequestedAddressIsValid,
} from '../cart/cart.selectors';
import { selectBaseLocale } from 'redux/i18n/i18n.selectors';
import { getDisplayInfoForLocale } from 'util/displayInfo';
import { slugify } from 'pages/locations/locations.utils';
import { selectors } from 'redux/app/app.slice';
import { IANAZone, SystemZone } from 'luxon';
import { PICKUP } from 'util/constants';
import {
  getRequestedCateringLocations,
  getRequestedDeliveryLocations,
  getRequestedPickupLocations,
} from './locations.utils';
import {
  orderTypePropSelector,
  storeIdPropSelector,
} from 'redux/propSelectors/propSelectors';
import {
  selectCustomCatalogIsActive,
  selectIsCateringAvailable,
} from 'redux/config/config.selectors';
import { selectors as appSelectors } from 'redux/app/app.slice';

export const selectLocations = state => state.locations;

export const selectMenuBrowsingStores = createSelector(
  selectLocations,
  locations => locations?.menuBrowsingStores ?? [],
);

export const selectCateringMenuBrowsingStores = createSelector(
  selectLocations,
  locations => locations?.cateringMenuBrowsingStores ?? [],
);

export const selectStores = createSelector(
  selectLocations,
  locations => locations?.stores ?? [],
);

export const selectIsStoresFetching = createSelector(
  selectLocations,
  locations => locations.isStoresFetching,
);

export const selectIsMenuBrowsingStoresFetching = createSelector(
  selectLocations,
  locations => locations.isMenuBrowsingStoresFetching,
);

export const selectIsCateringMenuBrowsingStoresFetching = createSelector(
  selectLocations,
  locations => locations.isCateringMenuBrowsingStoresFetching,
);

export const selectIsCateringStoresFetching = createSelector(
  selectLocations,
  locations => locations.isCateringStoresFetching,
);

export const selectIsLocationsLoading = createSelector(
  selectIsStoresFetching,
  selectIsCateringStoresFetching,
  selectIsMenuBrowsingStoresFetching,
  selectIsCateringMenuBrowsingStoresFetching,
  (
    isStoresFetching,
    isCateringStoresFetching,
    isMenuBrowsingStoresFetching,
    isCateringMenuBrowsingStoresFetching,
  ) =>
    isStoresFetching ||
    isCateringStoresFetching ||
    isMenuBrowsingStoresFetching ||
    isCateringMenuBrowsingStoresFetching,
);

export const selectTotalLocationPages = createSelector(
  selectLocations,
  selectors.selectLocationsLocationType,
  (locations, locationType) => {
    if (locationType === 'CATERING') {
      return locations.cateringPaging?.totalPages;
    }
    return locations.paging?.totalPages;
  },
);

export const selectLatestFetchedPage = createSelector(
  selectLocations,
  selectors.selectLocationsLocationType,
  (locations, locationType) => {
    if (locationType === 'CATERING') {
      return locations.cateringPaging?.currentPage;
    }
    return locations.paging?.currentPage;
  },
);

export const selectAvailableStores = createSelector([selectStores], stores =>
  stores
    ? stores.filter(
        store => store.pickupOrdersAccepted || store.deliveryOrdersAccepted,
      )
    : [],
);

export const makeSelectIsStoreAvailable = storeId =>
  createSelector(
    selectAllAvailableStores,
    availableStores =>
      !!availableStores.find(store => store.storeId === storeId),
  );

export const selectCateringStores = createSelector(
  [selectLocations],
  locations => locations?.cateringStores ?? [],
);

export const selectAvailableCateringStores = createSelector(
  [selectCateringStores],
  cateringStores =>
    cateringStores
      ? cateringStores.filter(
          store => store.pickupOrdersAccepted || store.deliveryOrdersAccepted,
        )
      : [],
);

export const selectAvailablePickupStores = createSelector(
  [selectAvailableStores],
  availableStores =>
    availableStores.filter(
      availableStore => availableStore.pickupOrdersAccepted,
    ),
);

export const selectAvailableDeliveryStores = createSelector(
  selectAvailableStores,
  availableStores =>
    availableStores.filter(
      availableStore => availableStore.deliveryOrdersAccepted,
    ),
);

export const selectAllAvailableStores = createSelector(
  [
    selectAvailablePickupStores,
    selectAvailableDeliveryStores,
    selectAvailableCateringStores,
    selectMenuBrowsingStores,
    selectCateringMenuBrowsingStores,
  ],
  (
    pickupStores,
    deliveryStores,
    cateringStores,
    menuBrowsingStores,
    cateringMenuBrowsingStores,
  ) => [
    ...pickupStores,
    ...deliveryStores,
    ...cateringStores,
    ...menuBrowsingStores,
    ...cateringMenuBrowsingStores,
  ],
);

export const selectRequestedStore = createSelector(
  selectAllAvailableStores,
  storeIdPropSelector,
  (stores, storeId) => stores.find(store => store.storeId === storeId),
);

export const selectRequestedStoreValueLimits = createSelector(
  selectRequestedStore,
  orderTypePropSelector,
  (store, orderType) =>
    orderType === PICKUP
      ? store?.pickupOrderValueLimits
      : store?.deliveryOrderValueLimits,
);

export const selectRequestedStoreMinValue = createSelector(
  [selectRequestedStoreValueLimits],
  orderValueLimits => orderValueLimits?.minOrderValue,
);

export const selectRequestedStoreMaxValue = createSelector(
  [selectRequestedStoreValueLimits],
  orderValueLimits => orderValueLimits?.maxOrderValue,
);

export const selectRequestedStoreOrderValueMessages = createSelector(
  selectRequestedStoreValueLimits,
  selectBaseLocale,
  (orderValueLimits, baseLocale) =>
    getDisplayInfoForLocale(orderValueLimits?.displayMessage, baseLocale),
);

export const selectRequestedStoreOrderValueLongMessage = createSelector(
  selectRequestedStoreOrderValueMessages,
  requestedStoreOrderValueMessages =>
    requestedStoreOrderValueMessages?.longMessage,
);

export const selectRequestedStoreOrderValueShortMessage = createSelector(
  selectRequestedStoreOrderValueMessages,
  requestedStoreOrderValueMessages =>
    requestedStoreOrderValueMessages?.shortMessage,
);

export const selectRequestedStoreAddress = createSelector(
  [selectRequestedStore],
  store => (store ? store.address : null),
);

export const selectRequestedStoreDI = createSelector(
  [selectRequestedStore, selectBaseLocale],
  (store, baseLocale) =>
    getDisplayInfoForLocale(store?.displayInfo, baseLocale),
);

export const selectRequestedStoreDITitle = createSelector(
  [selectRequestedStoreDI],
  displayInfo => displayInfo?.title,
);

export const makeSelectStoreDI = store =>
  createSelector(selectBaseLocale, baseLocale =>
    getDisplayInfoForLocale(store?.displayInfo, baseLocale),
  );

export const selectIsCatering = requestedStoreId =>
  createSelector(
    selectAvailableCateringStores,
    selectMenuBrowsingStores,
    (availableCateringStores, menuBrowsingStores) =>
      !![...availableCateringStores, ...menuBrowsingStores].find(
        store => store.storeId === requestedStoreId,
      ),
  );

/**
 * Returns whether a store is a menu browsing [view menu] store.
 * If requestedStoreId is not provided it will return to the store id in the redux
 * @param {string} [requestedStoreId] Id of the requested store
 * @returns {boolean} indicating the store is menu browsing or not
 */

export const makeSelectIsMenuBrowsingStore = requestedStoreId =>
  createSelector(
    selectMenuBrowsingStores,
    selectCateringMenuBrowsingStores,
    selectLocationId,
    (menuBrowsingStores, cateringMenuBrowisngStores, locationId) =>
      [...menuBrowsingStores, ...cateringMenuBrowisngStores].some(
        store => store.storeId === (requestedStoreId ?? locationId),
      ),
  );

export const selectStoreById = requestedStoreId =>
  createSelector([selectAllAvailableStores], stores =>
    stores
      ? stores.find(
          availableStore => availableStore.storeId === requestedStoreId,
        )
      : null,
  );

export const selectOrderingDisabledStores = createSelector(
  selectStores,
  stores =>
    stores.filter(
      store => !store.pickupOrdersAccepted && !store.deliveryOrdersAccepted,
    ),
);

export const selectCateringDisabledStores = createSelector(
  selectCateringStores,
  stores =>
    stores.filter(
      store => !store.pickupOrdersAccepted && !store.deliveryOrdersAccepted,
    ),
);

export const selectDisabledStores = createSelector(
  selectOrderingDisabledStores,
  selectCateringDisabledStores,
  selectors.selectLocationsLocationType,
  (orderingDisabledStores, cateringDisabledStores, locationType) => {
    if (locationType === 'CATERING') {
      return cateringDisabledStores;
    }
    return orderingDisabledStores;
  },
);

export const selectRequestedStores = createSelector(
  selectAvailablePickupStores,
  selectAvailableDeliveryStores,
  selectAvailableCateringStores,
  selectMenuBrowsingStores,
  selectCateringMenuBrowsingStores,
  selectors.selectLocationsLocationType,
  selectors.selectLocationsOrderType,
  selectRequestedAddressIsValid,
  appSelectors.selectSearchLocation,
  (
    pickupAvailableStores,
    deliveryAvailableStores,
    cateringAvailableStores,
    menuBrowsingStores,
    cateringMenuBrowsingStores,
    locationType,
    orderType,
    requestedAddressIsValid,
    searchLocation,
  ) => {
    if (locationType === 'CATERING')
      return getRequestedCateringLocations(
        cateringAvailableStores.concat(cateringMenuBrowsingStores),
      );

    if (orderType === 'DELIVERY' || pickupAvailableStores.length === 0)
      return getRequestedDeliveryLocations(
        deliveryAvailableStores.concat(menuBrowsingStores),
        requestedAddressIsValid,
      );

    return getRequestedPickupLocations(
      pickupAvailableStores.concat(menuBrowsingStores),
      searchLocation,
    );
  },
);

export const selectIsAllDeliveryLocationsOutOfRange = createSelector(
  selectRequestedAddressIsValid,
  selectAvailableDeliveryStores,
  (requestedAddressIsValid, availableDeliveryStores) =>
    requestedAddressIsValid &&
    availableDeliveryStores.every(
      store => store.withInDeliveryRadius === false,
    ),
);

export const selectRequestedStoresWithOrderingDisabled = createSelector(
  selectRequestedStores,
  selectDisabledStores,
  (requestedStores, disabledStores) => [...requestedStores, ...disabledStores],
);

export const selectIsPickupButtonVisible = createSelector(
  selectAvailablePickupStores,
  selectOrderingDisabledStores,
  selectMenuBrowsingStores,
  (pickupAvailableStores, disabledStores, menuBrowsingStores) =>
    pickupAvailableStores.length +
      disabledStores.length +
      menuBrowsingStores.length >
    0,
);

export const selectIsDeliveryButtonVisible = createSelector(
  selectAvailableDeliveryStores,
  deliveryAvailableStores => deliveryAvailableStores.length > 0,
);

export const selectIsCateringButtonVisible = createSelector(
  selectAvailableCateringStores,
  selectCateringDisabledStores,
  selectCateringMenuBrowsingStores,
  (cateringAvailableStores, disabledStores, cateringMenuBrowsingStores) =>
    cateringAvailableStores.length +
      disabledStores.length +
      cateringMenuBrowsingStores.length >
    0,
);

export const makeSelectStoreBySlug = slug =>
  createSelector(
    selectStores,
    selectCateringStores,
    selectMenuBrowsingStores,
    selectCateringMenuBrowsingStores,
    (
      stores,
      cateringStores,
      menuBrowsingLocations,
      cateringMenuBrowsingLocations,
    ) => {
      const findStore = stores =>
        stores.find(store => slugify(store?.displayInfo?.[0]?.title) === slug);
      return (
        findStore(stores) ??
        findStore(cateringStores) ??
        findStore(menuBrowsingLocations) ??
        findStore(cateringMenuBrowsingLocations)
      );
    },
  );

export const selectPaging = createSelector(
  selectLocations,
  locations => locations.paging,
);

export const selectCateringPaging = createSelector(
  selectLocations,
  locations => locations.cateringPaging,
);

export const selectMenuBrowsingPaging = createSelector(
  selectLocations,
  locations => locations.menuBrowsingPaging,
);

export const selectCateringMenuBrowsingPaging = createSelector(
  selectLocations,
  locations => locations.cateringMenuBrowsingPaging,
);

const hasMorePages = paging => paging?.totalPages > paging?.currentPage + 1;

export const selectHasMoreLocations = createSelector(selectPaging, paging =>
  hasMorePages(paging),
);

export const selectHasMoreCateringLocations = createSelector(
  selectCateringPaging,
  paging => hasMorePages(paging),
);

export const selectHasMoreMenuBrowsingLocations = createSelector(
  selectMenuBrowsingPaging,
  paging => hasMorePages(paging),
);

export const selectHasMoreCateringMenuBrowsingLocations = createSelector(
  selectCateringMenuBrowsingPaging,
  paging => hasMorePages(paging),
);

export const selectDisplayTimeZone = createSelector(
  selectRequestedStore,
  store => (!!store ? IANAZone.create(store.timeZone) : SystemZone.instance),
);

export const selectPickupMaxDaysOut = createSelector(
  selectRequestedStore,
  store => store?.pickupMaxDaysOut,
);

export const selectDeliveryMaxDaysOut = createSelector(
  selectRequestedStore,
  store => store?.deliveryMaxDaysOut,
);

export const selectMaxDaysOrderingDays = createSelector(
  selectRequestedStore,
  selectOrderType,
  (store, orderType) => {
    if (orderType === PICKUP)
      return !!store?.pickupSevenPlusDaysOrdering
        ? store.pickupMaxDaysOut
        : undefined;

    return !!store?.deliverySevenPlusDaysOrdering
      ? store.deliveryMaxDaysOut
      : undefined;
  },
);

export const selectIsLocationLoading = createSelector(
  selectLocations,
  locations => locations.isStoreFetching,
);

export const selectAreAllLocationsTypesLoadedOnce = createSelector(
  selectLocations,
  selectIsCateringAvailable,
  selectCustomCatalogIsActive,
  (locations, isCateringAvailable, isMenuBrowsingAvailable) => {
    return (
      !!locations.stores &&
      (isMenuBrowsingAvailable ? !!locations.menuBrowsingStores : true) &&
      (isCateringAvailable && isMenuBrowsingAvailable
        ? !!locations.cateringMenuBrowsingStores
        : true) &&
      (isCateringAvailable ? !!locations.cateringStores : true)
    );
  },
);

export const selectDisableTipping = createSelector(
  selectRequestedStore,
  store => store?.disableTipping,
);
