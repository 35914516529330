import { getAddressFromPlaceSuggestion } from 'pages/locations/locations.utils';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import usePlacesAutocomplete from 'use-places-autocomplete';
import AddressAutoCompleteInput from './address-autocomplete-input/address-autocomplete-input.component';
import AddressAutocompleteOption from './address-autocomplete-option/address-autocomplete-option.component';
import * as S from './address-autocomplete.styles';
import { getOptionLabel } from './address-autocomplete.utils';
import HiddenTypography from 'components/hidden-typography/hidden-typography.component';
import { useTranslation } from 'react-i18next';

const AddressAutocomplete = ({
  name,
  defaultValue,
  rules = {},
  autocompleteProps,
  autocompleteInputProps,
}) => {
  const { t } = useTranslation();
  const {
    suggestions: { data },
    setValue: setPlacesValue,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 750,
  });

  const handleInputChange = useCallback(
    (_e, newValue) => setPlacesValue(newValue),
    [setPlacesValue],
  );

  const { handleChange, ...autocompletePropsRest } = autocompleteProps;

  return (
    <Controller
      name={name}
      id={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, ...fieldRest } }) => (
        <>
          <HiddenTypography id="address-control-instructions">
            {t('addressSelect.aria.instructions')}
          </HiddenTypography>
          <HiddenTypography aria-live="polite">
            {t('addressSelect.aria.noOfOptions', { count: data.length })}
          </HiddenTypography>
          <S.CustomAutocomplete
            {...fieldRest}
            fullWidth
            filterOptions={x => x}
            getOptionLabel={getOptionLabel}
            options={data}
            includeInputInList
            getOptionSelected={(option, value) =>
              option.place_id === value.placeId
            }
            onChange={async (_e, newValue) => {
              if (!newValue) return onChange(null);
              const address = await getAddressFromPlaceSuggestion(newValue);
              const value = {
                ...address,
                description: newValue.description,
                main_text: newValue.structured_formatting?.main_text,
              };
              onChange(value);
              handleChange?.(value);
            }}
            onInputChange={handleInputChange}
            renderInput={({ inputProps, ...paramsRest }) => (
              <AddressAutoCompleteInput
                name={name}
                fullWidth
                {...paramsRest}
                inputProps={{
                  'aria-labelledby': 'address-control-label',
                  'aria-describedby': 'address-control-instructions',
                  ...inputProps,
                  ...autocompleteInputProps,
                }}
              />
            )}
            renderOption={option => (
              <AddressAutocompleteOption option={option} />
            )}
            {...autocompletePropsRest}
          />
        </>
      )}
    />
  );
};

export default AddressAutocomplete;
